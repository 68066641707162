* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

@font-face {
	font-family: "Frutiger Next Light";
	src:url("css/fonts/FrutigerNextLTW06-Light.eot?#iefix");
	src:url("css/fonts/FrutigerNextLTW06-Light.eot?#iefix") format("eot"),url("css/fonts/FrutigerNextLTW06-Light.woff2") format("woff2"),url("css/fonts/FrutigerNextLTW06-Light.woff") format("woff"),url("css/fonts/FrutigerNextLTW06-Light.ttf") format("truetype");
}

html {
	font-family: "Frutiger Next Light", Arial, sans-serif;
	color: #4c4c4c;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body {
	padding: 0;
	margin: 0;
	background: #efefef;
}

.container {
	max-width: 1296px;
	margin: auto;
	background: #fff;
}

.header {
	padding: 25px 60px;
	border-top: 10px solid #e2002a;
	width: 100%
}

.content {
	padding: 20px 60px 20px 50%;
	margin-left: -20px;
	width: 100%;
}

@media screen and (max-width: 720px) {
	.header {
		padding: 25px 20px;

	}

	.content {
		padding: 20px;
		margin-left: 0;
	}
}

a.btn {
	margin: 0 0 20px 0;
	display: inline-block;
	background-color: #E2002A;
	border: 1px solid #E2002A;
	padding: 10px;
	color: #fff;
	font-weight: bold;
	text-decoration: none;

	font-size: 18px;
}

a.btn:focus, a.btn:hover {
	background-color: #fff;
	color: #E2002A;
}

a.btn:active {
	background-color: #fff;
	color: #000;
}

#error-page-image {
	display:block;
	width:100%;
	height:auto;
}
